<template>
  <div class="">
    <div class="flex flex-col gap-4">
      <div class="grid gap-2 cursor-pointer" @click="preview.openRestaurant(restaurant)">
        <div class="aspect-video bg-sand rounded-sm overflow-hidden relative">
          <div class="absolute z-10 top-2 left-2 bg-sky text-ocean text-xs px-2 py-0.5 rounded-sm" v-if="restaurant.promoted">
            {{ restaurant.promoted.text }}
          </div>
          <AtomImage :src="useCloudinaryImage(restaurant.cover, 600, 400)" />
        </div>
        <div class="grid gap-1 flex-1">
          <h2 class="font-bold text-xl">{{ restaurant.name }}</h2>
          <div class="flex items-center gap-2">
            <AtomIcon name="location-dot" class="text-coral" />
            <p class="bg-mist px-2 rounded-full text-xs py-0.5 border" v-if="restaurant.distance">{{ restaurant.distance }}km</p>
            <p class="text-sm opacity-60 line-clamp-1" v-html="[restaurant.address, restaurant.city].join(', ')" />
          </div>
          <p class="text-sm">{{ restaurant.shortDescription }}</p>
        </div>
      </div>
      <div v-if="restaurant.meals?.length" class="grid gap-2 overflow-hidden">
        <div v-for="meal in restaurant.meals" :key="meal.id" class="overflow-hidden">
          <p class="font-medium text-sm mb-1">{{ meal.name }}</p>
          <div class="flex gap-2 overflow-x-auto" v-auto-animate>
            <div v-for="timeslot in meal.timeslots" :key="'timeslot'+meal.id+timeslot.timestamp" class="text-sm">
              <button class="w-[3.75em] py-0.5 rounded-sm" @click="useWidgetOpen(restaurant.hash, meal.id, fat.amount, fat.date, timeslot.time)" :class="{ 'bg-coral text-white': timeslot.available, 'bg-sand/50 text-black/50': timeslot.waitlist }">
                {{ timeslot.time }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const fat = useFatStore()
const preview = usePreviewStore()

const props = defineProps({
  restaurant: {
    type: Object,
    default: () => {}
  }
})
</script>