<template>
  <FatFilterModal>
    <div class="p-4 sticky z-30 top-0 bg-white">
      <div class="flex justify-between">
        <div>
          <DialogTitle as="h2" class="font-bold text-2xl">{{ $t('fat.filterHeading') }}</DialogTitle>
          <DialogDescription class="hidden"></DialogDescription>
        </div>
        <div class="flex items-center gap-2 text-sm cursor-pointer" @click="fatInit">
          <div>{{ $t('shared.close') }}</div>
          <AtomIcon name="circle-xmark" />
        </div>
      </div>
    </div>
    <div class="px-4 pt-2 pb-24 sm:pb-4 overflow-y-auto">
      <div class="grid gap-6">
        <div class="grid gap-2">
          <FatFilterTagsFeatures />
        </div>
        <div class="grid gap-4">
          <div class="grid gap-0.5">
            <div class="text-xl font-bold">{{ $t('fat.cuisineTags.heading') }}</div>
            <div class="text-sm">{{ $t('fat.cuisineTags.leading') }}</div>
          </div>
          <FatFilterTagsCuisines />
        </div>
        <div class="grid gap-2">
          <div class="grid gap-0.5">
            <div class="text-xl font-bold">{{ $t('fat.genericTags.heading') }}</div>
          </div>
          <FatFilterTagsGeneric />
        </div>
        <div class="grid gap-2">
          <div class="grid gap-0.5">
            <div class="text-xl font-bold">{{ $t('fat.dishTags.heading') }}</div>
          </div>
          <FatFilterTagsDishes />
        </div>
      </div>
    </div>
    <div class="p-4 grid gap-4 sticky z-30 bottom-0 bg-white">
      <button class="button relative text-sm w-full" @click="fatInit" :class="{ 'pointer-events-none': fat.loading }" data-style="coral">
        <AtomSpinner class="w-4 text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0" :class="{ 'opacity-100': fat.loading }" />
        <span :class="{ 'opacity-0': fat.loading }">
          {{ $t('fat.button', { count: route.name === 'restaurants' ? total : '' }) }}
        </span>
      </button>
      <button class="button-outline relative text-sm w-full" @click="clearFilter" :class="{ 'pointer-events-none': fat.loading }" data-style="sand">
        {{ $t('fat.buttonClearFilter') }}
      </button>
    </div>
  </FatFilterModal>
</template>

<script async setup>
const ui = useUiStore()
const fat = useFatStore()
const route = useRoute()
const router = useRouter()

// Navigate to search result
const fatInit = () => {
  ui.fatFilter = false
  fat.pushQuery()
}

// Clear Filter tags ID's
const clearFilter = () => {
  fat.tags = {
    cousineIds: null,
    amenityIds: null,
    tagIds: null,
    dishIds: null
  }

  router.push({ query: { 
    ...route.query, 
    paged: undefined, 
    tagIds: undefined, 
    cousineIds: undefined,
    amenityIds: undefined,
    dishIds: undefined
  }})

  ui.fatFilter = false
}

// Use FAT result data
const { data: fatResult } = useNuxtData('fat-result')
const total = computed(() => fatResult.value?.data?.total)
</script>