<template>
  <div class="w-full max-w-6xl mx-auto overflow-hidden">
    <div class="mb-3">
      <h1 class="font-bold text-2xl mt-4 mb-2">{{ $t('fat.heading', { count: total }) }}</h1>
      <div class="inline-flex items-center gap-2 cursor-pointer" @click="ui.fatSearch = !ui.fatSearch">
        <div class="border pl-0.5 pr-3 rounded-full">
          <div class="flex items-center gap-2 text-sm">
            <div class="rounded-full flex items-center justify-center text-white bg-coral w-5 h-5 text-xs font-bold">{{ fat.amount }}</div>
            <AtomIcon name="users" class="w-6" />
            <div class="flex items-center border-l pl-2 py-0.5">
              {{ $t('fat.edit') }}
            </div>
          </div>
        </div>
        <span class="text-sm">{{ $t('fat.leading', leadingParams) }}</span>
      </div>
      <!-- <span class="flex items-center gap-2 cursor-pointer" @click="ui.fatSearch = !ui.fatSearch">
        <div class="h-6 flex items-center justify-center border rounded-full cursor-pointer" @click="ui.fatSearch = !ui.fatSearch">
          <AtomIcon name="users" class="text-xs" />
          <div>Ändra</div>
        </div>
        <span class="text-sm">{{ $t('fat.leading', leadingParams) }}</span>
      </span> -->
    </div>
    <div class="flex justify-between">
      <div class="flex gap-2">
        <!-- <div class="border px-3 py-0.5 rounded-full cursor-pointer" @click="ui.fatSearch = !ui.fatSearch">
          <div class="flex items-center gap-2 text-sm cursor-pointer">
            <AtomIcon name="users" />
            <div>{{ fat.amount }}</div>
          </div>
        </div> -->
        <FatSearchMode />
        <FatFilter />
      </div>
      <div class="flex items-center gap-2">
        <div class="border pl-3 pr-0.5 py-0.5 rounded-full cursor-pointer" @click="ui.fatFilter = !ui.fatFilter">
          <div class="flex items-center gap-2 text-sm">
            <AtomIcon name="bars-filter" />
            <div class="flex items-center" v-auto-animate>
              <div class="mr-2" v-html="`${$t('fat.filter')}`" />
              <div class="bg-coral text-xs font-bold rounded-full text-white flex items-center justify-center w-5 h-5" v-html="`${fat.filterCount}`" v-if="fat.filterCount" />
            </div>
          </div>
        </div>
        <div class="hidden sm:flex items-center rounded-full border overflow-hidden">
          <div class="px-3 flex items-center h-full cursor-pointer transition-all border-r" :class="{ 'bg-mist': ui.fatLayout === 'card', 'opacity-50': ui.fatLayout !== 'card' }" @click="ui.fatLayout = 'card'">
            <AtomIcon name="grid-2" class="h-6 text-sm" />
          </div>
          <div class="px-3 flex items-center h-full cursor-pointer transition-all border-r" :class="{ 'bg-mist': ui.fatLayout === 'row', 'opacity-50': ui.fatLayout !== 'row' }" @click="ui.fatLayout = 'row'">
            <AtomIcon name="list" class="h-6 text-sm" />
          </div>
          <div class="px-3 flex items-center h-full cursor-pointer transition-all" :class="{ 'bg-mist': ui.fatLayout === 'minimal', 'opacity-50': ui.fatLayout !== 'minimal' }" @click="ui.fatLayout = 'minimal'">
            <AtomIcon name="grip-lines" class="h-6 text-sm" />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="debug my-2">
      {{ fat.$state }}
    </div> -->
    <!-- <div class="debug my-2">
      {{ route.query }}
    </div> -->
    <!-- <div class="debug my-2">
      {{ leadingParams }}
      <br>
      {{ ui.fatLayout === 'row' }}
      <br>
      {{ ui.$state }}
    </div> -->
    <!-- <div class="debug my-2">
      limit: {{ limit }}
      <br>
      paged: {{ paged }}
      <br>
      pages: {{ pages }}
      <br>
      pagination: {{ pages > paged }}
      <br>
      <br>
      <br>
      restaurants: {{ restaurants.length }}
    </div> -->
    <div class="text-sm mt-6 rounded-md bg-mist p-4" v-if="!restaurants?.length">{{ $t('fat.noResult') }}</div>
    <div class="grid grid-cols-6 mt-6" :class="{ 'gap-x-6 gap-y-10': ui.fatLayout === 'card', 'gap-6': ui.fatLayout === 'row', 'gap-2': ui.fatLayout === 'minimal' }" v-auto-animate v-if="restaurants?.length">
      <div v-for="restaurant in restaurants" :key="restaurant.id" class="transition-all" :class="{ 'col-span-full': ['row', 'minimal'].includes(ui.fatLayout), 'col-span-full sm:col-span-3 lg:col-span-2': ui.fatLayout === 'card' }">
        <FatResultRow :restaurant="restaurant" v-if="ui.fatLayout === 'row'" />
        <FatResultCard :restaurant="restaurant" v-if="ui.fatLayout === 'card'" />
        <FatResultMinimal :restaurant="restaurant" v-if="ui.fatLayout === 'minimal'" />
      </div>
    </div>
    <div class="mt-12" v-if="pages > paged">
      <button class="button text-sm w-full sm:w-auto" data-style="coral" @click="appendRestaurants">
        {{ $t('fat.buttonMore') }}
      </button>
    </div>
    <ClientOnly>
      <FatRestaurants class="mt-12 border-t pt-12" />
    </ClientOnly>
  </div>
</template>

<script async setup>
const { t } = useI18n()
const route = useRoute()
const dayjs = useDayjs()
const ui = useUiStore()
const fat = useFatStore()
const limit = 24
const paged = ref(1)

// Update FAT amount if query is set
onMounted(() => {
  fat.setDate(route.query?.date)
  fat.setAmount(route.query?.amount)
  fat.setCityId(route.query?.cityId)
  fat.setLocation(route.query?.location)

  // Check if meal is passed as query from old FAT
  if (route.query?.meal) {
    fat.setMealType(route.query?.meal)
  }

  setTimeout(() => {
    fat.pushQuery()
  }, 100)
})

// Fetch restaurants from Elastic Search
const { data, pending } = await useFetch('/search/fat', {
  key: 'fat-result',
  baseURL: useEnv('apiBase'),
  query: computed(() => fat.query)
})

// Computed values
const total = computed(() => data.value?.data?.total)
const pages = computed(() => Math.ceil(total.value / limit))
const restaurants = computed(() => data.value?.data?.restaurants?.slice(0, limit * paged.value))

// Append more restaurants 
const appendRestaurants = () => {
  paged.value++
}

// Dynamic leading 
const leadingParams = computed(() => {
  let date = dayjs(fat.date).format('ddd D MMM')
  if (dayjs(fat.date).isSame('week')) date = dayjs(fat.date).format('dddd')
  if (dayjs(fat.date).isToday()) date = t('fat.date.today')
  if (dayjs(fat.date).isTomorrow()) date = t('fat.date.tomorrow')

  return {
    amount: fat.amount,
    date: date,
    timeRange: `${fat.mealStartTime}-${fat.mealEndTime}`
  }
})

// Watch pending
watch(() => pending.value, () => {
  window.scrollTo(0, 0)
  paged.value = 1
  fat.loading = pending.value
})
</script>

<style lang="scss" scoped>
// 
</style>