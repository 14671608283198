<template>
  <div class="cursor-pointer flex items-center gap-2" @click="onChange">
    <AtomSwitch :active="fat.excludeWaitlist" />
    <div class="text-sm whitespace-nowrap">
      <div class="sm:hidden" v-html="$t('fat.modeShort')" />
      <div class="hidden sm:block" v-html="$t('fat.modeLong')" />
    </div>
  </div>
</template>

<script setup>
const fat = useFatStore()

const onChange = () => {
  fat.setMode(!fat.excludeEmpty)
}
</script>

<style lang="scss" scoped>
// 
</style>