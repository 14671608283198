<template>
  <div class="border bg-mist/50 p-2 rounded-md">
    <h2 class="line-clamp-1 cursor-pointer" @click="preview.openRestaurant(restaurant)">
      <span class="font-bold">{{ restaurant.name }}</span>,
      <span class="opacity-60 text-sm" v-if="restaurant.distance">{{ restaurant.distance }}km, </span>
      <span class="opacity-60 text-sm">{{ [restaurant.address, restaurant.city].join(', ') }}</span>
    </h2>
    <div v-if="restaurant.meals?.length" class="grid gap-2 overflow-hidden mt-1">
      <div v-for="meal in restaurant.meals" :key="meal.id" class="overflow-hidden">
        <p class="font-medium text-sm mb-1">{{ meal.name }}</p>
        <div class="text-xs flex gap-1 overflow-x-auto" v-auto-animate>
          <div v-for="timeslot in meal.timeslots" :key="'timeslot'+meal.id+timeslot.timestamp">
            <button class="w-[3.75em] py-0.5 rounded-sm" @click="useWidgetOpen(restaurant.hash, meal.id, fat.amount, fat.date, timeslot.time)" :class="{ 'bg-coral text-white': timeslot.available, 'bg-sand/50 text-black/50': timeslot.waitlist }">
              {{ timeslot.time }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const fat = useFatStore()
const preview = usePreviewStore()

const props = defineProps({
  restaurant: {
    type: Object,
    default: () => {}
  }
})
</script>