<template>
  <div class="grid gap-4" v-if="restaurants.length">
    <div class="grid gap-0.5">
      <h2 class="font-bold text-2xl" v-html="$t('fat.list.heading')" />
      <p class="text-sm" v-html="$t('fat.list.leading')" />
    </div>
    <div class="list" v-auto-animate v-if="restaurants">
      <div v-for="restaurant in restaurants" :key="restaurant.id" class="list__item">
        <div class="card" @click="preview.openRestaurant(restaurant)">
          <div class="card__media">
            <div class="card__badge" v-if="restaurant.promoted" v-html="restaurant.promoted.text" />
            <AtomImage :src="useCloudinaryImage(restaurant.cover, 400, 300)" />
          </div>
          <div class="card__info">
            <h2 class="font-bold" v-html="restaurant.name" />
            <div class="card__meta">
              <AtomIcon name="location-dot" class="text-coral" />
              <p class="bg-mist px-2 rounded-full text-xs py-0.5 border" v-if="restaurant.distance" v-html="`${restaurant.distance.toFixed(1)}km`" />
              <p class="text-sm opacity-60 line-clamp-1" v-html="[restaurant.address, restaurant.city].join(', ')" />
            </div>
            <p class="text-sm" v-html="restaurant.shortDescription" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script async setup>
const route = useRoute()
const restaurants = ref([])
const preview = usePreviewStore()

// Use FAT result data
const { data: fatResult } = useNuxtData('fat-result')
const fatIds = ref(fatResult?.value?.data.restaurants.map(rest => rest.id))

// Function to fetch restaurants
async function fetchRestaurants() {
  const response = await $fetch('/restaurants', {
    key: 'fat-restaurants',
    baseURL: useEnv('apiBase'),
    params: {
      limit: 48,
      bookable: true,
      scope: 'bokabord.se',
      sortBy: route.query?.location ? 'distance' : 'rank',
      orderBy: route.query?.location ? 'asc' : 'desc',
      cities: route.query?.cityId,
      latitude: route.query?.location?.split(',')[0],
      longitude: route.query?.location?.split(',')[1],
      distance: route.query?.location?.split(',')[2],
      exclude: fatIds.value?.join(','),
      tags: [
        route.query.amenityIds,
        route.query.cousineIds,
        route.query.dishIds,
        route.query.tagIds
      ].filter(tag => tag).join('+') || undefined,
    },
  })
  restaurants.value = response.data
}

// Initial fetch
fetchRestaurants()

// Watch changes in FAT result to trigger new fetch
watch(() => fatResult.value, () => {
  fatIds.value = fatResult?.value?.data?.restaurants?.map(rest => rest.id)
  fetchRestaurants()
})
</script>

<style lang="scss" scoped>
.list {
  @apply grid grid-cols-12 gap-4 md:gap-6;

  &__item {
    @apply col-span-6 md:col-span-4 lg:col-span-3;
  }
}

.card {
  @apply flex flex-col gap-2;

  &__media {
    @apply aspect-video bg-sand rounded-sm overflow-hidden relative;
  }

  &__badge {
    @apply absolute z-10 top-2 left-2 bg-sky text-ocean text-xs px-2 py-0.5 rounded-sm;
  }

  &__info {
    @apply grid gap-1 flex-1;
  }

  &__meta {
    @apply flex items-center gap-2;
  }
}
</style>