<template>
  <div class="tags">
    <div v-for="tag in tags" :key="tag.id" class="tags__item">
      <div class="tags__link" :class="{ 'disabled': !tag.count, 'active': tag.active }" @click="toggleTag(tag.id)">
        <div class="flex items-center justify-center">
          <AtomIcon :name="tag.icon" class="py-1 text-lg" />
          <div class="text-sm font-medium ml-2 transition-all max-w-12" v-html="tag.count" :class="{ 'disabled': !tag.count, 'text-[0px] !ml-0 !max-w-0 opacity-0': tag.active || !tag.count }" />
        </div>
        <div class="text-[0.6875rem] sm:text-xs" v-html="tag.label" />
      </div>
    </div>
  </div>
</template>

<script setup>
import filters from '@/data/filters.js';

const route = useRoute()
const router = useRouter()
const fat = useFatStore()

// Use FAT result data
const { data: fatResult } = useNuxtData('fat-result')
const fatTags = computed(() => fatResult.value?.data?.filters?.tags)

// Amenities tags
const tags = computed(() => 
  filters?.amenities
  // ?.filter(tag => tag.group.includes('feature'))
    ?.filter(tag => tag.active)
    ?.map(tag => ({
      ...tag,
      active: queryTagIds.value.includes(tag.id),
      count: fatTags.value.find(fatTag => fatTag.key === tag.id)?.doc_count || 0
    }))
)

// Get cousine tags ID's as numeric values
const queryTagIds = computed(() => (route.query?.amenityIds ?? '')
  .split(';')
  .map(tag => parseInt(tag))
  .filter(tag => tag))

// Toggle tag
const toggleTag = (tagId) => {
  let tagIds = queryTagIds.value

  // Remove or add tag ID based on tags in query param
  queryTagIds.value.includes(tagId)
    ? tagIds = tagIds.filter(tag => tag !== tagId)
    : tagIds.push(tagId)

  // Save ID's in FAT store
  fat.tags.amenityIds = tagIds.length ? tagIds.join(';') : undefined

  // Push Amenity ID's and reset paged
  router.push({ query: { 
    ...route.query, 
    paged: undefined, 
    amenityIds: tagIds.length ? tagIds.join(';') : undefined 
  }})
}
</script>

<style lang="scss" scoped>
.tags {
  @apply grid grid-cols-3 gap-2;

  &__item {
    @apply flex-1;
  }

  &__link {
    @apply relative cursor-pointer w-full text-center;
    @apply grid gap-2 bg-mist rounded-md pt-3 pb-2;

    &.active {
      // @apply bg-sky text-ocean;
      @apply bg-coral text-white;
    }

    &.disabled {
      @apply pointer-events-none cursor-default text-black/20;
    }
  }

  &__pill {
    @apply text-sm font-bold my-1;
  }
}
</style>