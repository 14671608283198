<template>
  <div class="tags" v-auto-animate>
    <div v-for="tag in tags" :key="tag.id" class="tags__item">
      <!-- <div class="tags__link" :class="{ 'disabled': !tag.count, 'active': tag.active }" @click="toggleTag(tag.id)">
        <span class="text-sm inline-block mr-1" v-html="tag.label" />
        <span class="text-xs" :class="{ 'opacity-40': !tag.active }">({{ tag.count }})</span>
      </div> -->
      <div class="tags__link" :class="{ 'active': tag.active }" @click="toggleTag(tag.id)">
        <span class="text-sm inline-block" v-html="tag.label" />
      </div>
    </div>
  </div>
</template>

<script setup>
import filters from '@/data/filters.js';

const route = useRoute()
const router = useRouter()
const fat = useFatStore()

// Use FAT result data
const { data: fatResult } = useNuxtData('fat-result')
const fatTags = computed(() => fatResult.value?.data?.filters?.tags)

// Cuisines tags
const tags = computed(() => 
  filters?.cuisines
    ?.filter(tag => tag.active)
    ?.map(tag => ({
      ...tag,
      active: queryTagIds.value.includes(tag.id),
      count: fatTags.value.find(fatTag => fatTag.key === tag.id)?.doc_count || 0
    }))
    // .sort((a, b) => !a.count - !b.count)
)

// Get cousine tags ID's as numeric values
const queryTagIds = computed(() => (route.query?.cousineIds ?? '')
  .split(',')
  .map(tag => parseInt(tag))
  .filter(tag => tag))

// Toggle tag
const toggleTag = (tagId) => {
  let tagIds = queryTagIds.value

  // Remove or add tag ID based on tags in query param
  queryTagIds.value.includes(tagId)
    ? tagIds = tagIds.filter(tag => tag !== tagId)
    : tagIds.push(tagId)

  // Save ID's in FAT store
  fat.tags.cousineIds = tagIds.length ? tagIds.join(',') : undefined 

  // Push Cousine ID's and reset paged
  router.push({ query: { 
    ...route.query, 
    paged: undefined, 
    cousineIds: tagIds.length ? tagIds.join(',') : undefined 
  }})
}
</script>

<style lang="scss" scoped>
.tags {
  @apply flex flex-wrap gap-2;

  &__item {
    // 
  }

  &__link {
    @apply cursor-pointer transition-all;
    @apply bg-mist rounded-sm px-2 py-0.5 whitespace-nowrap;

    &:hover {
      @apply bg-sand/50;
    }

    &.active {
      // @apply bg-sky text-ocean;
      @apply bg-coral text-white;
    }

    &.disabled {
      @apply pointer-events-none cursor-default text-black/20;
    }
  }
}
</style>