
export default {
  amenities: [
    {
      'id': 94, 
      'active': true,
      'label': 'Uteservering',
      'icon': 'sunglasses',
      'group': ['feature']
    },
    {
      'id': 105, 
      'active': true,
      'label': 'Chambre Separée',
      'icon': 'users',
      'group': ['necessity']
    },
    {
      'id': 103, 
      'active': true,
      'label': 'Avsmakningsmeny',
      'icon': 'square-list',
      'group': ['necessity']
    },
    {
      'id': 96, 
      'active': true,
      'label': 'Hundvänligt',
      'icon': 'dog',
      'group': ['feature']
    },
    { 
      'id': 97, 
      'active': true,
      'label': 'Samtalsvänligt', 
      'icon': 'comments',
      'group': ['visiting'] 
    },
    {
      'id': 107, 
      'active': true,
      'label': 'Rullstolsanpassat',
      'icon': 'wheelchair',
      'group': ['necessity']
    }
  ],
  tags: [
    { 
      'id': 62, 
      'active': true,
      'label': 'Dejt', 
      'group': ['type'] 
    },
    { 
      'id': 63, 
      'active': true,
      'label': 'Helkväll', 
      'group': ['type'] 
    },
    { 
      'id': 64, 
      'active': true,
      'label': 'AW', 
      'group': ['type'] 
    },
    { 
      'id': 65, 
      'active': true,
      'label': 'Familjärt', 
      'group': ['type'] 
    },
    {
      'id': 100, 
      'active': true,
      'label': 'Barnstol',
      'icon': 'baby',
      'group': ['feature']
    },
    { 
      'id': 66, 
      'active': true,
      'label': 'Avslappnat', 
      'group': ['type'] 
    },
    { 
      'id': 67, 
      'active': true,
      'label': 'Trendigt', 
      'group': ['type'] 
    },
    { 
      'id': 68, 
      'active': true,
      'label': 'Fest', 
      'group': ['type'] 
    },
    { 
      'id': 69, 
      'active': true,
      'label': 'Stimmigt', 
      'group': ['type'] 
    },
    { 
      'id': 70, 
      'active': true,
      'label': 'Drinkar', 
      'group': ['type'] 
    },
    { 
      'id': 71, 
      'active': true,
      'label': 'Mysigt', 
      'group': ['type'] 
    },
    { 
      'id': 72, 
      'active': true,
      'label': 'Lyxigt', 
      'group': ['type'] 
    },
    { 
      'id': 73, 
      'active': true,
      'label': 'Barhäng', 
      'group': ['type'] 
    },
    { 
      'id': 74, 
      'active': true,
      'label': 'Klassiskt', 
      'group': ['type'] 
    },
    { 
      'id': 75, 
      'active': true,
      'label': 'Business', 
      'group': ['type'] 
    },
    { 
      'id': 76, 
      'active': false,
      'label': 'Drop-in', 
      'group': ['type'] 
    },
    { 
      'id': 95, 
      'active': true,
      'label': 'Cocktailbar', 
      'group': ['visiting'] 
    },
    { 
      'id': 98, 
      'active': true,
      'label': 'Drop in', 
      'group': ['visiting'] 
    },
    {
      'id': 122, 
      'active': true,
      'label': 'Takterrass',
      'group': ['visiting']
    },
    { 
      'id': 99, 
      'active': true,
      'label': 'DJ', 
      'group': ['visiting'] 
    },
    { 
      'id': 104, 
      'active': true,
      'label': 'Livemusik', 
      'group': ['visiting'] 
    },
    { 
      'id': 123, 
      'active': true,
      'label': 'Sjö/havsutsikt', 
      'group': ['visiting'] 
    },
    { 
      'id': 101, 
      'active': true,
      'label': 'Sportbar', 
      'group': ['visiting'] 
    },
    { 
      'id': 106, 
      'active': true,
      'label': 'Nattklubb', 
      'group': ['visiting'] 
    },
    { 
      'id': 102, 
      'active': true,
      'label': 'Vinbar', 
      'group': ['visiting'] 
    },
    { 
      'id': 124, 
      'active': true,
      'label': 'Ölprovningar', 
      'group': ['visiting'] 
    },
    { 
      'id': 125, 
      'active': true,
      'label': 'Vinprovningar', 
      'group': ['visiting'] 
    }
  ],
  cuisines: [
    {
      'id': 88, 
      'active': true,
      'label': 'Svenskt',
      'group': ['popular']
    },
    {
      'id': 81, 
      'active': true,
      'label': 'Nordiskt',
      'group': ['popular']
    },
    {
      'id': 78, 
      'active': true,
      'label': 'Italienskt',
      'group': ['popular']
    },
    {
      'id': 85, 
      'active': true,
      'label': 'Franskt',
      'group': ['popular']
    },
    {
      'id': 87, 
      'active': true,
      'label': 'Spanskt',
      'group': ['popular']
    },
    {
      'id': 82, 
      'active': true,
      'label': 'Sydamerikanskt',
      'group': []
    },
    {
      'id': 77, 
      'active': true,
      'label': 'Sydeuropeiskt',
      'group': []
    },
    {
      'id': 79, 
      'active': true,
      'label': 'Mexikanskt',
      'group': []
    },
    {
      'id': 80, 
      'active': true,
      'label': 'Amerikanskt',
      'group': []
    },
    {
      'id': 83, 
      'active': true,
      'label': 'Internationellt',
      'group': []
    },
    {
      'id': 84, 
      'active': true,
      'label': 'Centraleuropeiskt',
      'group': []
    },
    {
      'id': 86, 
      'active': true,
      'label': 'Indiskt',
      'group': []
    },
    {
      'id': 89, 
      'active': true,
      'label': 'Thailändskt',
      'group': []
    },
    {
      'id': 90, 
      'active': true,
      'label': 'Japanskt',
      'group': []
    },
    {
      'id': 91, 
      'active': true,
      'label': 'Asiatiskt',
      'group': []
    },
    {
      'id': 92, 
      'active': true,
      'label': 'Vietnamesiskt',
      'group': []
    },
    {
      'id': 93, 
      'active': true,
      'label': 'Kinesiskt',
      'group': []
    },
    {
      'id': 108, 
      'active': true,
      'label': 'Grekiskt',
      'group': ['popular']
    },
    {
      'id': 109, 
      'active': true,
      'label': 'Latinamerikanskt',
      'group': ['popular']
    },
    // {
    //   'id': 110, 
    //   'active': false,
    //   'label': 'Mellanöstern'
    // },
    // {
    //     'id': 111, 
    //   'active': false,
    //     'label': 'Skaldjur'
    // },
    // {
    //     'id': 112, 
    //   'active': false,
    //     'label': 'Steakhouse'
    // },
    // {
    //     'id': 113, 
    //   'active': false,
    //     'label': 'Fiskrestaurang'
    // }
  ],
  dishes: [
    {
      'id': 22,
      'active': true,
      'label': 'Pasta Bolognese'
    },
    {
      'id': 23,
      'active': true,
      'label': 'Pasta Carbonara'
    },
    {
      'id': 24,
      'active': true,
      'label': 'Fish & chips'
    },
    {
      'id': 25,
      'active': true,
      'label': 'Fiskgryta'
    },
    {
      'id': 26,
      'active': true,
      'label': 'Hamburgare'
    },
    {
      'id': 27,
      'active': false,
      'label': 'Steak frites'
    },
    {
      'id': 28,
      'active': true,
      'label': 'Köttbullar'
    },
    {
      'id': 29,
      'active': true,
      'label': 'Wienerschnitzel'
    },
    {
      'id': 30,
      'active': false,
      'label': 'Sushi'
    },
    {
      'id': 31,
      'active': true,
      'label': 'Omelett'
    },
    {
      'id': 32,
      'active': true,
      'label': 'Råbiff'
    },
    {
      'id': 126,
      'active': true,
      'label': 'Toast Skagen'
    }
  ]
}
